<template>
  <div>
    <v-dialog
      scrollable
      persistent
      v-model="visible"
      v-on:keydown.esc="fecharJanelaSalvar()"
      v-bind:max-width="
        utilImp.cnt.CONFIG.tema.janela.tamanho.largura.maxima.filtro
      "
    >
      <v-card>
        <v-card-title v-bind="utilImp.cnt.CONFIG.tema.janela.barraSuperior">
          <span class="headline">{{ lang("compartilhar") }}</span>
          <v-divider class="mx-2" inset horizontal></v-divider>
          <v-btn icon v-on:click="fecharJanelaSalvar()">
            <v-icon v-bind="utilImp.cnt.CONFIG.tema.janela.icone">{{
              utilImp.cnt.CONFIG.icon.fechar
            }}</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-data-table
            class="elevation-0"
            height="500px"
            fixed-header
            hide-default-footer
            v-bind:headers="titulo"
            v-bind:items="arrayUsuario"
            v-bind:items-per-page="itemPagina"
          >
            <!-- Campo -->
            <template v-slot:item.selecionado="{ item }">
              <v-checkbox
                primary
                false-value="0"
                true-value="1"
                v-model="item.selecionado"
                v-bind="utilImp.cnt.CONFIG.checkbox"
              ></v-checkbox>
            </template>
            <template v-slot:top>
              <v-alert
                v-bind:value="true"
                color="white"
                icon="mdi-information-outline"
                >{{ mensagem }}</v-alert
              >
            </template>
            <template v-slot:no-data>
              <v-alert
                v-bind:value="false"
                color="white"
                icon="mdi-information-outline"
                >{{ lang("nenhumRegistroEncontrado") }}</v-alert
              >
            </template>
          </v-data-table>
        </v-card-text>
        <v-divider />
        <v-card-actions v-bind="utilImp.cnt.CONFIG.tema.janela.rodape">
          <v-spacer />
          <v-btn
            v-on:click="fecharJanelaSalvar()"
            v-bind:disabled="desabilitado"
            v-bind="utilImp.cnt.CONFIG.btn.cancelar"
            ><v-icon left>{{ utilImp.cnt.CONFIG.btn.cancelar.icone }}</v-icon
            >{{ lang("cancelar") }}</v-btn
          >
          <v-btn
            v-on:click="salvarRegistro()"
            v-shortkey.once="utilImp.cnt.CONFIG.btn.salvar.atalho"
            v-on:shortkey="salvarRegistro()"
            v-bind:disabled="desabilitado"
            v-bind="utilImp.cnt.CONFIG.btn.salvar"
            ><v-icon left>{{ utilImp.cnt.CONFIG.btn.salvar.icone }}</v-icon
            >{{ lang("salvar") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Componentes -->
  </div>
</template>

<script>
import util from "../../../util/Util.js";
const ITEM_PAGINA = 5000; // v-data-table

export default {
  components: {},
  mixins: [util.mixUtil, util.mixAlerta],
  props: {
    idConsole: {
      type: Number,
      default: null
    },
    mensagem: {
      type: String,
      default: ""
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    utilImp: util,
    desabilitado: false,
    titulo: [],
    arrayUsuario: [],
    itemPagina: ITEM_PAGINA
  }),
  computed: {},
  watch: {
    visible() {
      if (this.visible) {
        this.listarUsuario();
      }
    }
  },
  created() {
    this.iniciar();
  },
  mounted() {},
  methods: {
    iniciar() {
      // Títulos
      this.titulo = [
        {
          text: this.lang("selecao"),
          align: "left",
          value: "selecionado",
          sortable: true
        },
        {
          text: this.lang("usuario"),
          align: "left",
          value: "nomeCompleto",
          sortable: true
        },
        {
          text: this.lang("unidade"),
          align: "left",
          value: "unidadeBEA.unidade",
          sortable: true
        }
      ];
    },
    listarUsuario() {
      this.desabilitar(true);
      this.limpar();
      this.showLoader();
      util.srv.console
        .listarUsuarioParaCompartilhamento(this.idConsole)
        .then(response => {
          this.arrayUsuario = response.data.result;
        })
        .catch(error => {
          this.alertError(this.lang("erroOperacao"));
          console.log(error);
        })
        .finally(() => {
          this.desabilitar(false);
          this.hideLoader();
        });
    },
    salvarRegistro() {
      this.compartilharConsole();
    },
    compartilharConsole() {
      this.desabilitar(true);
      util.srv.console
        .compartilharConsole(this.idConsole, this.arrayUsuario)
        .then(response => {
          if (response.data.resultCode === util.cnt.SERVICO.mensagem.sucesso) {
            this.alertSucess(this.lang("sucesso"));
          } else {
            this.alertWarning(this.langResultCode(response.data.resultCode));
          }
        })
        .catch(error => {
          this.alertError(this.lang("erroOperacao"));
          console.log(error);
        })
        .finally(() => {
          this.desabilitar(false);
          this.fecharJanelaSalvar();
        });
    },
    fecharJanelaSalvar() {
      this.$emit("visible", false);
    },
    desabilitar(desabilitado) {
      this.desabilitado = desabilitado;
    },
    // exibir(idConsole) {
    //   this.idConsole = idConsole;
    //   this.listarUsuario();
    //   this.visible = true;
    // },
    itemSelecionado() {},
    limpar() {
      this.arrayUsuario = [];
    }
  }
};
</script>
