<template>
  <div>
    <v-card class="mx-auto elevation-0">
      <v-card-title>
        <v-toolbar
          flat
          v-bind="utilImp.cnt.CONFIG.tema.detalhe.barraSuperior.fundo"
        >
          <v-icon
            left
            v-bind="utilImp.cnt.CONFIG.tema.detalhe.barraSuperior.icone"
            >{{
              utilImp.cnt.CONFIG.tema.detalhe.barraSuperior.icone.nome
            }}</v-icon
          >
          <v-toolbar-title
            v-bind="utilImp.cnt.CONFIG.tema.detalhe.barraSuperior.titulo"
            >{{ lang("consoleSql") }}</v-toolbar-title
          >
          <v-divider class="mx-4" inset horizontal></v-divider>
          <div class="flex-grow-2"></div>
          <v-btn
            v-on:click="abrirJanelaFiltro()"
            v-shortkey.once="utilImp.cnt.CONFIG.btn.pesquisar.atalho"
            v-on:shortkey="
              !desabilitado && permissaoListar() ? abrirJanelaFiltro() : null
            "
            v-bind="utilImp.cnt.CONFIG.btn.pesquisar"
            v-bind:disabled="desabilitado || !permissaoListar()"
            ><v-icon left>{{ utilImp.cnt.CONFIG.btn.pesquisar.icone }}</v-icon
            >{{ lang("pesquisar") }}</v-btn
          >
          <v-btn
            v-on:click="modoIncluir()"
            v-shortkey.once="utilImp.cnt.CONFIG.btn.incluir.atalho"
            v-on:shortkey="
              !desabilitado && permissaoIncluir() ? modoIncluir() : null
            "
            v-bind:disabled="desabilitado || !permissaoIncluir()"
            v-bind="utilImp.cnt.CONFIG.btn.incluir"
            ><v-icon left>{{ utilImp.cnt.CONFIG.btn.incluir.icone }}</v-icon
            >{{ lang("incluir") }}</v-btn
          >
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <!-- Listar -->
        <v-data-table
          class="elevation-0"
          height="70vh"
          fixed-header
          hide-default-footer
          v-bind:headers="titulo"
          v-bind:items="arrayConsole.result"
          v-bind:items-per-page="itemPagina"
        >
          <!-- Campo -->

          <!-- Acao Extra -->
          <template v-slot:item.acoes="{ item }">
            <v-icon
              class="mr-2"
              v-bind:title="lang('alterar')"
              v-on:click="modoAlterar(item)"
              v-bind:disabled="desabilitado || !permissaoAlterar()"
              >{{
                utilImp.cnt.CONFIG.icon.alterar +
                  " " +
                  utilImp.cnt.CONFIG.icon.tamanho.grande
              }}</v-icon
            >
            <v-icon
              class="mr-2"
              v-bind:title="lang('excluir')"
              v-on:click="modoExcluir(item)"
              v-bind:disabled="desabilitado || !permissaoExcluir()"
              >{{
                utilImp.cnt.CONFIG.icon.excluir +
                  " " +
                  utilImp.cnt.CONFIG.icon.tamanho.grande
              }}</v-icon
            >

            <v-icon
              class="mr-2"
              v-bind:title="lang('executar')"
              v-on:click="modoExecutar(item)"
              v-bind:disabled="desabilitado || !permissaoExecutar()"
              >{{
                utilImp.cnt.CONFIG.icon.rodar +
                  " " +
                  utilImp.cnt.CONFIG.icon.tamanho.grande
              }}</v-icon
            >

            <v-menu offset-y open-on-hover>
              <template v-slot:activator="{ on }">
                <v-icon
                  class="mr-2"
                  v-on="on"
                  v-bind:style="{ cursor: 'pointer' }"
                  v-bind:title="lang('maisAcoes') + '...'"
                  v-bind:disabled="desabilitado || !permissaoAcao()"
                  >{{
                    utilImp.cnt.CONFIG.icon.acaoExtra +
                      " " +
                      utilImp.cnt.CONFIG.icon.tamanho.grande
                  }}</v-icon
                >
              </template>
              <v-list>
                <v-list-item
                  v-for="(itemAcaoExtra, index) in menuAcaoExtra"
                  v-bind:key="index"
                  v-on:click="acaoExtra(item, itemAcaoExtra.value)"
                  v-bind:disabled="desabilitado || itemAcaoExtra.disabled"
                >
                  <v-list-item-title>{{
                    itemAcaoExtra.title
                  }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <template v-slot:no-data>
            <v-alert v-bind:value="false" color="white" icon="info">{{
              lang("nenhumRegistroEncontrado")
            }}</v-alert>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-pagination
          v-model="page"
          v-bind="paginacao"
          v-bind:disabled="desabilitado || !permissaoListar()"
          v-on:input="pesquisar()"
        ></v-pagination>
        <v-spacer />
      </v-card-actions>
    </v-card>
    <!-- Pesquisar -->
    <v-dialog
      scrollable
      persistent
      v-model="dlgFiltrar"
      v-on:keydown.esc="validarFecharJanelaFiltro()"
      v-bind:max-width="
        utilImp.cnt.CONFIG.tema.janela.tamanho.largura.maxima.filtro
      "
    >
      <v-card>
        <v-card-title v-bind="utilImp.cnt.CONFIG.tema.janela.barraSuperior">
          <span class="headline">{{ lang("pesquisar") }}</span>
          <v-divider class="mx-4" inset horizontal></v-divider>
          <v-btn icon v-on:click="validarFecharJanelaFiltro()">
            <v-icon v-bind="utilImp.cnt.CONFIG.tema.janela.icone">{{
              utilImp.cnt.CONFIG.icon.fechar
            }}</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  name="namFiltroConsole"
                  ref="refFiltroConsole"
                  maxlength="30"
                  v-model="model.filtroConsole"
                  v-bind:label="lang('consulta')"
                  v-bind="utilImp.cnt.CONFIG.textfield"
                  v-on:keyup.enter="pesquisar()"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10" sm="10" md="10" />
              <v-col cols="2" sm="2" md="2">
                <v-select
                  style="max-width:100%"
                  v-model="qtdItemPagina"
                  v-bind:items="arrayItemPagina"
                  v-bind:label="lang('itensPorPagina')"
                  v-bind="utilImp.cnt.CONFIG.select"
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider />
        <v-card-actions v-bind="utilImp.cnt.CONFIG.tema.janela.rodape">
          <v-btn
            v-on:click="limparFiltro()"
            v-shortkey.once="utilImp.cnt.CONFIG.btn.limpar.atalho"
            v-on:shortkey="limparFiltro()"
            v-bind="utilImp.cnt.CONFIG.btn.limpar"
            ><v-icon left>{{ utilImp.cnt.CONFIG.btn.limpar.icone }}</v-icon
            >{{ lang("limpar") }}</v-btn
          >
          <div class="flex-grow-1"></div>
          <v-btn
            v-on:click="validarFecharJanelaFiltro()"
            v-bind="utilImp.cnt.CONFIG.btn.cancelar"
            ><v-icon left>{{ utilImp.cnt.CONFIG.btn.cancelar.icone }}</v-icon
            >{{ lang("cancelar") }}</v-btn
          >
          <v-btn
            v-on:click="confirmar()"
            v-shortkey.once="utilImp.cnt.CONFIG.btn.confirmar.atalho"
            v-on:shortkey="dlgFiltrar === true ? confirmar() : null"
            v-bind="utilImp.cnt.CONFIG.btn.confirmar"
            ><v-icon left>{{ utilImp.cnt.CONFIG.btn.confirmar.icone }}</v-icon
            >{{ lang("confirmar") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Salvar -->
    <v-dialog
      scrollable
      persistent
      v-model="dlgSalvar"
      v-bind:max-width="
        utilImp.cnt.CONFIG.tema.janela.tamanho.largura.maxima.salvar
      "
    >
      <v-card>
        <v-card-title v-bind="utilImp.cnt.CONFIG.tema.janela.barraSuperior">
          <span class="headline"
            >{{ cmpTituloAcao }} {{ lang("consulta") }}</span
          >
          <v-divider class="mx-4" inset horizontal></v-divider>
          <v-btn icon v-on:click="fecharJanelaSalvar(true)">
            <v-icon v-bind="utilImp.cnt.CONFIG.tema.janela.icone">{{
              utilImp.cnt.CONFIG.icon.fechar
            }}</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  name="namConsulta"
                  ref="refConsulta"
                  maxlength="50"
                  v-model="itemEditado.consoleBEA.consulta"
                  v-bind:label="
                    lang('consulta') + utilImp.cnt.CAMPO.obrigatorio
                  "
                  v-bind:disabled="!permissaoIncluir() || !permissaoAlterar()"
                  v-bind="utilImp.cnt.CONFIG.textfield"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  auto-grow
                  name="namAnotacao"
                  ref="refAnotacao"
                  maxlength="200"
                  rows="3"
                  v-model="itemEditado.consoleBEA.anotacao"
                  v-bind:label="lang('anotacao')"
                  v-bind:disabled="!permissaoIncluir() || !permissaoAlterar()"
                  v-bind="utilImp.cnt.CONFIG.textarea"
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  auto-grow
                  name="namParametro"
                  ref="refParametro"
                  maxlength="200"
                  rows="3"
                  v-model="itemEditado.consoleBEA.parametro"
                  v-bind:label="lang('parametros')"
                  v-bind:disabled="!permissaoIncluir() || !permissaoAlterar()"
                  v-bind="utilImp.cnt.CONFIG.textarea"
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  auto-grow
                  name="namCodigoSql"
                  ref="refCodigoSql"
                  maxlength="200"
                  rows="10"
                  v-model="itemEditado.consoleBEA.codigoSql"
                  v-bind:label="lang('sql') + utilImp.cnt.CAMPO.obrigatorio"
                  v-bind:disabled="!permissaoIncluir() || !permissaoAlterar()"
                  v-bind="utilImp.cnt.CONFIG.textarea"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider />
        <v-card-actions v-bind="utilImp.cnt.CONFIG.tema.janela.rodape">
          <div class="flex-grow-1"></div>
          <v-btn
            v-on:click="fecharJanelaSalvar(false)"
            v-bind:disabled="desabilitado"
            v-bind="utilImp.cnt.CONFIG.btn.cancelar"
            ><v-icon left>{{ utilImp.cnt.CONFIG.btn.cancelar.icone }}</v-icon
            >{{ lang("cancelar") }}</v-btn
          >
          <v-btn
            v-on:click="salvar()"
            v-shortkey.once="utilImp.cnt.CONFIG.btn.salvar.atalho"
            v-on:shortkey="dlgSalvar === true ? salvar() : null"
            v-bind:disabled="desabilitado"
            v-bind="utilImp.cnt.CONFIG.btn.salvar"
            ><v-icon left>{{ utilImp.cnt.CONFIG.btn.salvar.icone }}</v-icon
            >{{ lang("salvar") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Exibir SQL -->
    <v-dialog
      scrollable
      persistent
      v-model="dlgSql"
      b-bind:disabled="desabilitado"
      v-on:keydown.esc="fecharJanelaSql()"
      v-bind:max-width="
        utilImp.cnt.CONFIG.tema.janela.tamanho.largura.maxima.filtro
      "
    >
      <v-card>
        <v-card-title v-bind="utilImp.cnt.CONFIG.tema.janela.barraSuperior">
          <span class="headline">{{ lang("sql") }}</span>
          <v-divider class="mx-2" inset horizontal></v-divider>
          <v-btn icon v-on:click="fecharJanelaSql()">
            <v-icon v-bind="utilImp.cnt.CONFIG.tema.janela.icone">{{
              utilImp.cnt.CONFIG.icon.fechar
            }}</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  name="namCodigoSqlConsulta"
                  v-model="console.consulta"
                  v-bind:label="lang('consulta')"
                  v-bind:disabled="true"
                  v-bind="utilImp.cnt.CONFIG.textfield"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  auto-grow
                  rows="3"
                  name="namCodigoSqlAnotacao"
                  v-model="console.anotacao"
                  v-bind:label="lang('anotacao')"
                  v-bind:disabled="true"
                  v-bind="utilImp.cnt.CONFIG.textfield"
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  auto-grow
                  rows="3"
                  name="namCodigoSqlParametro"
                  v-model="console.parametro"
                  v-bind:label="lang('parametros')"
                  v-bind:disabled="true"
                  v-bind="utilImp.cnt.CONFIG.textarea"
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  auto-grow
                  rows="3"
                  name="namCodigoSqlCodigoSql"
                  v-model="console.codigoSql"
                  v-bind:label="lang('sql')"
                  v-bind:disabled="true"
                  v-bind="utilImp.cnt.CONFIG.textarea"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider />
        <v-card-actions v-bind="utilImp.cnt.CONFIG.tema.janela.rodape">
          <v-spacer />
          <v-btn
            v-on:click="fecharJanelaSql()"
            v-bind:disabled="desabilitado"
            v-bind="utilImp.cnt.CONFIG.btn.cancelar"
            ><v-icon left>{{ utilImp.cnt.CONFIG.btn.cancelar.icone }}</v-icon
            >{{ lang("cancelar") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Componentes -->
    <CompartilharConsole
      v-bind:idConsole="propCompartilharConsole.idConsole"
      v-bind:mensagem="propCompartilharConsole.mensagem"
      v-bind:visible="propCompartilharConsole.visible"
      v-on:visible="propCompartilharConsole.visible = $event"
    />
    <ExecutarConsole
      v-bind:idUsuario="propExecutarConsole.idUsuario"
      v-bind:idConsole="propExecutarConsole.idConsole"
      v-bind:consulta="propExecutarConsole.consulta"
      v-bind:parametro="propExecutarConsole.parametro"
      v-bind:anotacao="propExecutarConsole.anotacao"
      v-bind:visible="propExecutarConsole.visible"
      v-on:visible="propExecutarConsole.visible = $event"
    />
  </div>
</template>

<script>
import util from "../../../util/Util.js";
import CompartilharConsole from "@/views/relatorio/console/CompartilharConsole.vue";
import ExecutarConsole from "@/views/relatorio/console/ExecutarConsole.vue";

const ACAO_INCLUIR = "acaoIncluir";
const ACAO_ALTERAR = "acaoAlterar";
const ACAO_LISTAR = "acaoListar";
const ACAO_FECHAR_JANELA_FILTRO = "acaoFecharJanelaFiltro";
const ACAO_EXTRA_COMPARTILHAR = "acaoExtraCompartilhar";
const ACAO_EXTRA_VISUALIZAR = "acaoExtraVisualizar";
const ACAO_EXTRA_CLONAR = "acaoExtraClonar";
const ITEM_PAGINA = 5000; // v-data-table
const ARRAY_ITEM_PAGINA = util.cnt.ARRAY.itemPagina1.qtd;
const POSICAO_ITEM_PAGINA = util.cnt.ARRAY.itemPagina1.posicao.p1;

export default {
  components: { CompartilharConsole, ExecutarConsole },
  mixins: [util.mixUtil, util.mixAlerta],

  data: () => ({
    utilImp: util,
    dlgFiltrar: false,
    dlgSalvar: false,
    dlgSql: false,
    titulo: [],
    arrayConsole: [],
    menuAcaoExtra: [],
    desabilitado: false,
    page: 1,
    itemPagina: ITEM_PAGINA,
    arrayItemPagina: ARRAY_ITEM_PAGINA,
    qtdItemPagina: ARRAY_ITEM_PAGINA[POSICAO_ITEM_PAGINA - 1],
    paginacao: { length: 1, totalVisible: 5, disabled: false },
    model: { filtroConsole: "" },
    console: { consulta: "", parametro: "", codigoSql: "", anotacao: "" },
    propCompartilharConsole: {
      idConsole: null,
      mensagem: "",
      visible: false,
    },
    propExecutarConsole: {
      idConsole: null,
      idUsuario: null,
      consulta: "",
      parametro: "",
      anotacao: "",
      visible: false,
    },
    validacao: {
      filtroConsole: "max:30",
      consulta: "required|max:100",
      parametro: "max:200",
      codigoSql: "required",
      anotacao: "max:200",
    },
    indice: -1,
    itemEditado: {
      consoleBEA: {
        idConsole: null,
        usuarioBEA: { idUsuario: null, nomeCompleto: "" },
        consulta: "",
        parametro: "",
        codigoSql: "",
        anotacao: "",
      },
      usuarioBEA: { id_usuario: null },
    },
    itemPadrao: {
      consoleBEA: {
        idConsole: null,
        usuarioBEA: { idUsuario: null, nomeCompleto: "" },
        consulta: "",
        parametro: "",
        codigoSql: "",
        anotacao: "",
      },
      usuarioBEA: { id_usuario: null },
    },
  }),

  computed: {
    cmpTituloAcao() {
      return this.indice === -1 ? this.lang("incluir") : this.lang("alterar");
    },
  },

  watch: {},

  created() {
    this.iniciar();
  },

  mounted() {
    this.pesquisar();
  },

  methods: {
    iniciar() {
      this.titulo = [
        // align: 'start' | 'center' | 'end'
        {
          text: this.lang("consulta"),
          align: "left",
          value: "consoleBEA.consulta",
          sortable: true,
        },
        {
          text: this.lang("anotacao"),
          align: "left",
          value: "consoleBEA.anotacao",
          sortable: true,
        },
        {
          text: this.lang("criador"),
          align: "left",
          value: "consoleBEA.usuarioBEA.nomeCompleto",
          sortable: true,
        },
        {
          text: this.lang("acoes"),
          align: "center",
          value: "acoes",
          sortable: false,
        },
      ];

      // Menu de opção extra
      this.menuAcaoExtra = [
        {
          title: this.lang("compartilhar"),
          value: ACAO_EXTRA_COMPARTILHAR,
          disabled: !this.permissaoCompartilhar(),
        },
        {
          title: this.lang("visualizar"),
          value: ACAO_EXTRA_VISUALIZAR,
          disabled: !this.permissaoVisualizar(),
        },
        {
          title: this.lang("clonar"),
          value: ACAO_EXTRA_CLONAR,
          disabled: !this.permissaoClonar(),
        },
      ];
    },

    modoIncluir() {
      this.indice = -1;
      this.itemEditado = Object.assign({}, this.itemPadrao);
      this.itemEditado.consoleBEA = Object.assign(
        {},
        this.itemPadrao.consoleBEA
      );
      this.abrirJanelaSalvar();
      this.setFocusConsulta();
    },

    modoAlterar(item) {
      this.indice = this.arrayConsole.result.indexOf(item);
      this.itemEditado = Object.assign({}, item);
      this.itemEditado.consoleBEA = Object.assign({}, item.consoleBEA);
      this.abrirJanelaSalvar();
      this.setFocusConsulta();
    },

    modoExcluir(item) {
      this.confirmDelete(
        this.lang("excluirConsulta"),
        item.consoleBEA.consulta
      ).then((result) => {
        if (result.value) {
          this.excluir(item);
        }
      });
    },

    modoExecutar(item) {
      this.propExecutarConsole.idConsole = item.consoleBEA.idConsole;
      this.propExecutarConsole.idUsuario = item.usuarioBEA.idUsuario;
      this.propExecutarConsole.consulta = item.consoleBEA.consulta;
      this.propExecutarConsole.parametro = item.consoleBEA.parametro;
      this.propExecutarConsole.anotacao = item.consoleBEA.anotacao;
      this.propExecutarConsole.visible = true;
    },

    modoClonar(item) {
      this.confirm(
        this.lang("clonarRegistro"),
        this.lang("desejaClonarRegistro") + " " + item.consoleBEA.consulta
      ).then((result) => {
        if (result.value) {
          this.clonarRegistro(item);
        }
      });
    },

    salvar() {
      let acao = "";
      if (this.indice > -1) {
        // Alterar
        acao = ACAO_ALTERAR;
      } else {
        // Incluir
        acao = ACAO_INCLUIR;
      }
      this.validarSalvar(acao);
    },

    async validarSalvar(acao) {
      let result = "";
      result = await util.val.validate(
        this.itemEditado.consoleBEA.consulta,
        this.validacao.consulta
      );
      if (!result.valid) {
        this.alertWarningToast(
          util.val.message(result.errors, this.lang("consulta"))
        );
        return;
      }
      result = await util.val.validate(
        this.itemEditado.consoleBEA.anotacao,
        this.validacao.anotacao
      );
      if (!result.valid) {
        this.alertWarningToast(
          util.val.message(result.errors, this.lang("anotacao"))
        );
        return;
      }
      result = await util.val.validate(
        this.itemEditado.consoleBEA.parametro,
        this.validacao.parametro
      );
      if (!result.valid) {
        this.alertWarningToast(
          util.val.message(result.errors, this.lang("parametro"))
        );
        return;
      }
      result = await util.val.validate(
        this.itemEditado.consoleBEA.codigoSql,
        this.validacao.codigoSql
      );
      if (!result.valid) {
        this.alertWarningToast(util.val.message(result.errors, this.lang("sql")));
        return;
      }
      // Direcionar para inclusão
      if (acao === ACAO_INCLUIR) {
        this.incluir();
      }
      // Direcionar para alteração
      if (acao === ACAO_ALTERAR) {
        this.alterar();
      }
    },

    incluir() {
      this.desabilitar(true);
      util.srv.console
        .incluirConsole(this.itemEditado.consoleBEA)
        .then((response) => {
          if (response.data.resultCode === util.cnt.SERVICO.mensagem.sucesso) {
            this.limparItemEditado();
            this.fecharJanelaSalvar(true);
            this.pesquisar();
            this.alertSucessToast(this.lang("sucesso"));
          } else {
            this.alertWarning(this.langResultCode(response.data.resultCode));
          }
        })
        .catch((error) => {
          this.alertError(this.lang("erroOperacao"));
          console.log(error);
        })
        .finally(() => {
          this.desabilitar(false);
        });
    },

    alterar() {
      this.desabilitar(true);
      util.srv.console
        .alterarConsole(this.itemEditado.consoleBEA)
        .then((response) => {
          if (response.data.resultCode === util.cnt.SERVICO.mensagem.sucesso) {
            this.limparItemEditado();
            this.fecharJanelaSalvar(true);
            this.pesquisar();
            this.alertSucessToast(this.lang("sucesso"));
          } else {
            this.alertWarning(this.langResultCode(response.data.resultCode));
          }
        })
        .catch((error) => {
          this.alertError(this.lang("erroOperacao"));
          console.log(error);
        })
        .finally(() => {
          this.desabilitar(false);
        });
    },

    excluir(item) {
      this.desabilitar(true);
      util.srv.console
        .excluirConsole(item.consoleBEA.idConsole)
        .then((response) => {
          if (response.data.resultCode === util.cnt.SERVICO.mensagem.sucesso) {
            this.pesquisar();
            this.alertSucess(this.lang("sucesso"));
          } else {
            this.alertWarning(this.langResultCode(response.data.resultCode));
          }
        })
        .catch((error) => {
          this.alertError(this.lang("erroOperacao"));
          console.log(error);
        })
        .finally(() => {
          this.desabilitar(false);
        });
    },

    clonarRegistro(item) {
      this.desabilitar(true);
      util.srv.console
        .clonarConsole(item.consoleBEA.idConsole)
        .then((response) => {
          if (response.data.resultCode === util.cnt.SERVICO.mensagem.sucesso) {
            this.pesquisar();
            this.alertSucess(this.lang("sucesso"));
          } else {
            this.alertWarning(this.langResultCode(response.data.resultCode));
          }
        })
        .catch((error) => {
          this.alertError(this.lang("erroOperacao"));
          console.log(error);
        })
        .finally(() => {
          this.desabilitar(false);
        });
    },

    confirmar() {
      this.limparPaginador();
      this.pesquisar();
    },

    pesquisar() {
      if (this.dlgFiltrar) {
        // Caso a janela filtrar esteja aberta, ocorrera a validacao.
        this.validarPesquisar(ACAO_LISTAR);
      } else {
        this.listar();
      }
    },

    async validarPesquisar(acao) {
      let result = "";
      result = await util.val.validate(
        this.model.filtroConsole,
        this.validacao.filtroConsole
      );
      if (!result.valid) {
        this.alertWarningToast(
          util.val.message(result.errors, this.lang("consulta"))
        );
        return;
      }
      // Direcionar para listar
      if (acao === ACAO_LISTAR) {
        this.listar();
      }
      // Direcionar para fechar a janela filtro
      if (acao === ACAO_FECHAR_JANELA_FILTRO) {
        this.fecharJanelaFiltro();
      }
    },

    listar() {
      this.desabilitar(true);
      this.limparLista();
      this.showLoader();
      util.srv.console
        .listarConsole(this.model.filtroConsole, this.pagina(), this.limite())
        .then((response) => {
          this.distribuir(response.data);
        })
        .catch((error) => {
          this.alertError(this.lang("erroOperacao"));
          console.log(error);
        })
        .finally(() => {
          this.desabilitar(false);
          this.fecharJanelaFiltro();
          this.hideLoader();
        });
    },

    distribuir(arrayRegistro) {
      if (arrayRegistro.resultCode === util.cnt.SERVICO.mensagem.sucesso) {
        if (arrayRegistro.result !== null) {
          this.arrayConsole = arrayRegistro;
          this.paginador();
        } else {
          // this.alertInfo(this.lang("nenhumRegistroEncontrado"));
        }
      } else {
        this.alertWarning(this.langResultCode(arrayRegistro.resultCode));
      }
    },

    acaoExtra(item, opcao) {
      if (opcao === ACAO_EXTRA_COMPARTILHAR) {
        this.propCompartilharConsole.idConsole = item.consoleBEA.idConsole;
        this.propCompartilharConsole.mensagem = this.langParametro(
          "compartilharConsultaCom",
          item.consoleBEA.consulta
        );
        this.propCompartilharConsole.visible = true;
      }
      if (opcao === ACAO_EXTRA_VISUALIZAR) {
        this.console.consulta = item.consoleBEA.consulta;
        this.console.parametro = item.consoleBEA.parametro;
        this.console.codigoSql = item.consoleBEA.codigoSql;
        this.console.anotacao = item.consoleBEA.anotacao;
        this.abrirJanelaSql();
      }
      if (opcao === ACAO_EXTRA_CLONAR) {
        this.modoClonar(item);
      }
    },

    verificarDataHoraLocal(idConsole) {
      this.desabilitar(true);
      util.srv.console
        .verificarHorario(idConsole)
        .then((response) => {
          if (response.data.resultCode === util.cnt.SERVICO.mensagem.sucesso) {
            this.alertInfo(response.data.result);
          } else {
            this.alertWarning(this.langResultCode(response.data.resultCode));
          }
        })
        .catch((error) => {
          this.alertError(this.lang("erroOperacao"));
          console.log(error);
        })
        .finally(() => {
          this.desabilitar(false);
        });
    },

    abrirJanelaFiltro() {
      this.dlgFiltrar = true;
      this.setFocusFiltroConsole();
    },
    abrirJanelaSalvar() {
      this.dlgSalvar = true;
    },
    abrirJanelaSql() {
      this.dlgSql = true;
    },

    fecharJanelaFiltro() {
      this.dlgFiltrar = false;
    },
    fecharJanelaSalvar(passeLivre) {
      if (!passeLivre) {
        if (!this.desabilitado) {
          this.dlgSalvar = false;
        }
      } else {
        this.dlgSalvar = false;
      }
    },
    fecharJanelaSql() {
      this.dlgSql = false;
    },
    /* Metodo responsavel por fechar a janela de pesquisa somente se os filtros forem validos */
    /* Necessario para que o paginador não execute a consulta com parametros invalidos */
    validarFecharJanelaFiltro() {
      this.validarPesquisar(ACAO_FECHAR_JANELA_FILTRO);
    },

    limparLista() {
      this.arrayConsole = [];
    },
    limparFiltro() {
      this.model.filtroConsole = "";
      this.setFocusFiltroConsole();
    },
    limparItemEditado() {
      this.itemEditado = Object.assign({}, this.itemPadrao);
    },
    limparPaginador() {
      this.page = 1;
      this.paginacao.length = 1;
    },

    desabilitar(desabilitado) {
      this.desabilitado = desabilitado;
    },

    setFocusFiltroConsole() {
      setTimeout(() => {
        this.$refs.refFiltroConsole.focus();
      }, util.cnt.CONFIG.timer.focus);
    },

    setFocusConsulta() {
      setTimeout(() => {
        this.$refs.refConsulta.focus();
      }, util.cnt.CONFIG.timer.focus);
    },

    iconeAtivo: function(valor) {
      return valor === util.cnt.BOLEANO.sim
        ? util.cnt.CONFIG.icon.sim
        : util.cnt.CONFIG.icon.nao;
    },

    paginador() {
      let contRegistro = this.arrayConsole.result.length;
      if (contRegistro >= this.qtdItemPagina) {
        this.paginacao.length = this.page + 1;
      }
    },

    pagina: function() {
      return this.page - 1;
    },

    limite: function() {
      return this.qtdItemPagina;
    },

    permissaoListar() {
      return this.verificarPermissao(util.prm.P_1023);
    },
    permissaoIncluir() {
      return this.verificarPermissao(util.prm.P_1024);
    },
    permissaoAlterar() {
      return this.verificarPermissao(util.prm.P_1025);
    },
    permissaoExcluir() {
      return this.verificarPermissao(util.prm.P_1026);
    },
    permissaoAcao() {
      return this.verificarPermissao(util.prm.P_1027);
    },
    permissaoExecutar() {
      return this.verificarPermissao(util.prm.P_1028);
    },
    permissaoVisualizar() {
      return this.verificarPermissao(util.prm.P_1029);
    },
    permissaoCompartilhar() {
      return this.verificarPermissao(util.prm.P_1030);
    },
    permissaoClonar() {
      return this.verificarPermissao(util.prm.P_1031);
    },
  },
};
</script>
