<template>
  <div>
    <v-dialog
      scrollable
      persistent
      fullscreen
      v-model="visible"
      v-on:keydown.esc="fecharJanelaExecutaConsole()"
    >
      <v-card>
        <v-card-title v-bind="utilImp.cnt.CONFIG.tema.janela.barraSuperior">
          <span class="headline">{{ lang("console") }}</span>
          <v-divider class="mx-2" inset horizontal></v-divider>
          <v-btn icon v-on:click="fecharJanelaExecutaConsole()">
            <v-icon v-bind="utilImp.cnt.CONFIG.tema.janela.icone">{{
              utilImp.cnt.CONFIG.icon.fechar
            }}</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <label>{{ lang("consulta") }}: {{ console.consulta }}</label>
          <br />
          <label>{{ lang("anotacao") }}: {{ console.anotacao }}</label>
          <template>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  auto-grow
                  name="namParametro"
                  rows="3"
                  v-model="console.parametro"
                  v-bind:label="lang('parametros')"
                  v-bind="utilImp.cnt.CONFIG.textarea"
                ></v-textarea>
              </v-col>
            </v-row>
          </template>
          <template>
            <v-simple-table fixed-header dense height="60vh">
              <thead>
                <tr>
                  <th
                    v-for="item in titulo"
                    v-bind:key="item.id"
                    class="text-left"
                  >
                    {{ item.text }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="linha in arrayConsole" v-bind:key="linha.id">
                  <td
                    v-for="item in linha"
                    v-bind:key="item.id"
                    class="text-xs-left"
                  >
                    {{ item }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </template>
        </v-card-text>
        <v-divider />
        <v-card-actions v-bind="utilImp.cnt.CONFIG.tema.janela.rodape">
          <v-container fill-height>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-pagination
                  v-model="page"
                  v-bind="propPaginacao"
                  v-bind:disabled="desabilitado"
                  v-on:input="validarPesquisa()"
                ></v-pagination>
              </v-col>
            </v-row>
          </v-container>
          <v-spacer />
          <v-btn
            v-on:click.stop.prevent="fecharJanelaExecutaConsole()"
            v-bind:disabled="desabilitado"
            v-bind="utilImp.cnt.CONFIG.btn.cancelar"
            ><v-icon left>{{ utilImp.cnt.CONFIG.btn.cancelar.icone }}</v-icon
            >{{ lang("cancelar") }}</v-btn
          >
          <v-btn
            v-on:click.stop.prevent="pesquisar()"
            v-bind:disabled="desabilitado"
            v-bind="utilImp.cnt.CONFIG.btn.executar"
            ><v-icon left>{{ utilImp.cnt.CONFIG.btn.executar.icone }}</v-icon
            >{{ lang("executar") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Componentes -->
  </div>
</template>

<script>
import util from "../../../util/Util.js";

export default {
  components: {},
  mixins: [util.mixUtil, util.mixAlerta],
  props: {
    idConsole: {
      type: Number,
      default: null
    },
    idUsuario: {
      type: Number,
      default: null
    },
    consulta: {
      type: String,
      default: ""
    },
    parametro: {
      type: String,
      default: ""
    },
    anotacao: {
      type: String,
      default: ""
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    utilImp: util,
    console: {
      idConsole: "",
      idUsuario: "",
      consulta: "",
      parametro: "",
      anotacao: ""
    },
    desabilitado: false,
    exibirItemPorPagina: false,
    titulo: [],
    arrayConsole: [],
    page: 1,
    qtdeLinha: "1000",
    propPaginacao: { length: 1, totalVisible: 5, disabled: false }
  }),
  computed: {},
  watch: {
    visible() {
      if (this.visible) {
        this.exibir();
      }
    }
  },
  created() {
    this.iniciar();
  },
  mounted() {},
  methods: {
    iniciar() {},
    pesquisar() {
      this.limparPaginador();
      this.validarPesquisa();
    },
    validarPesquisa() {
      this.limparLista();
      this.listarRegistro();
    },
    listarRegistro() {
      this.desabilitar(true);
      this.limparLista();
      util.srv.console
        .listarDadosConsole(
          this.console.idConsole,
          this.console.parametro,
          this.pagina(),
          this.limite()
        )
        .then(response => {
          if (response.data.resultCode === util.cnt.SERVICO.mensagem.sucesso) {
            this.distribuir(response.data);
          } else {
            this.alertWarning(this.langResultCode(response.data.resultCode));
          }
        })
        .catch(error => {
          this.alertError(this.lang("erroOperacao"));
          console.log(error);
        })
        .finally(() => {
          this.desabilitar(false);
        });
    },
    distribuir(arrayRegistro) {
      for (var i = 0; i < arrayRegistro.result.length; i++) {
        // Titulo
        if (i === 0) {
          let campoTitulo = arrayRegistro.result[i].split("|");
          for (var j = 0; j < campoTitulo.length; j++) {
            this.titulo.push({
              text: campoTitulo[j],
              align: "left",
              value: campoTitulo[j],
              sortable: true
            });
          }
          // Conteúdo
        } else {
          let campo = arrayRegistro.result[i].split("|");
          this.arrayConsole.push(campo);
          // Paginador
          this.paginar();
        }
      }
    },
    fecharJanelaExecutaConsole() {
      if (!this.desabilitado) {
        this.$emit("visible", false);
      }
    },
    desabilitar(desabilitado) {
      this.desabilitado = desabilitado;
    },
    paginar() {
      let contRegistro = this.arrayConsole.length;
      if (contRegistro >= this.qtdeLinha) {
        this.propPaginacao.length = this.page + 1;
      }
    },
    pagina: function() {
      return this.page - 1;
    },
    limite: function() {
      return this.qtdeLinha;
    },
    exibir() {
      this.limparLista();
      this.limparPaginador();
      this.console.idConsole = this.idConsole;
      this.console.idUsuario = this.idUsuario;
      this.console.consulta = this.consulta;
      this.console.parametro = this.parametro;
      this.console.anotacao = this.anotacao;
    },
    limparPaginador() {
      this.page = 1;
      this.propPaginacao.length = 1;
    },
    limparLista() {
      this.titulo = [];
      this.arrayConsole = [];
    }
  }
};
</script>
